<template>
  <div class="access">
    <h3 class="sub-title">Доступ к меню</h3>
    <div class="responsive">
      <v-skeleton-loader
        v-if="!load"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              v-bind:class="header.search ? 'text-left' : 'text-center'">
              {{header.text}}
              <div class="search-right" v-if="header.search">
                <input type="text">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="table in tables"
            :key="table.id"
          >
            <td class="text-center">{{table.name}}</td>
            <td class="text-center">
              <input type="checkbox" @change="createAccess(table)" v-model="table.administrator">
            </td>
            <td class="text-center">
              <input type="checkbox" @change="createAccess(table)" v-model="table.gvs">
            </td>
            <td class="text-center">
              <input type="checkbox" @change="createAccess(table)" v-model="table.cekkmp">
            </td>
            <td class="text-center">
              <input type="checkbox" @change="createAccess(table)" v-model="table.minZdravEmloyee">
            </td>
            <td class="text-center">
              <input type="checkbox" @change="createAccess(table)" v-model="table.guest">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Access',
    data () {
      return {
        load: false,
        headers: [
          {text: 'Раздел/Роль'},
          {text: 'Администратор'},
          {text: 'ГВС'},
          {text: 'ЦЭККМП'},
          {text: 'Минздрав'},
          {text: 'Пользователь'},
        ],
        tables: []
      }
    },
    computed : {
      ...mapGetters({
          getAccess: 'users/getAccess'
      }),
    },
    methods: {
      ...mapActions({
          access: 'users/access',
          createAccess: 'users/createAccess'
      }),
    },
    mounted() {
      this.access().then(() => {
        this.tables = this.getAccess
        this.load = true
      })
    }
  }
</script>
